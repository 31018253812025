<template>
  <div>
    <div class="head-card">
      <div class="head-card-top">
        <h4>{{ campaign.title }}</h4>
        <feather-icon
          v-if="closing"
          v-b-tooltip.hover.top="$t('Close')"
          icon="XIcon"
          size="18"
          class="toggle-close cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div
        class="head-card-bottom"
      >
        <template v-if="selectedBanners === 0">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ name: 'dashboard-user-campaign' }"
            exact
          >
            <feather-icon
              icon="GlobeIcon"
              size="16"
            />
            {{ $t('page.overview') }}
          </b-button>
        </template>
        <template v-if="$route.name === 'dashboard-campaigns-creatives' || $route.name === 'dashboard-campaigns-creatives-table'">
          <template v-if="campaign.type !== 'criteo' && campaign.type !== 'cpcfeed' && campaign.type !== 'adsense'">
            <b-button-toolbar
              class="align-items-center"
            >
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                :hidden="true"
                multiple
                plain
                @input="$emit('uploadBanners', $refs.refInputEl.files)"
              />
              <b-button
                v-if="selectedBanners === 0"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                @click="$refs.refInputEl.$el.click()"
              >
                <feather-icon
                  icon="UploadIcon"
                  size="16"
                />
                {{ $t('banners.upload') }}
              </b-button>
              <template v-else>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="success"
                  class="btn-icon"
                  @click="$emit('toggleStatusBanners', 'active')"
                >
                  <feather-icon
                    icon="PlayCircleIcon"
                    size="16"
                  />
                  {{ $t('form.turnon') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="secondary"
                  class="btn-icon"
                  @click="$emit('toggleStatusBanners', 'inactive')"
                >
                  <feather-icon
                    icon="StopCircleIcon"
                    size="16"
                  />
                  {{ $t('form.turnoff') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  @click="$emit('deleteBanner')"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                  {{ $t('form.delete') }}
                </b-button>
                <div class="ml-1">
                  {{ $t('form.selected') }}: {{ selectedBanners }}
                </div>
              </template>
            </b-button-toolbar>
          </template>
        </template>
        <template v-else-if="campaign.type !== 'criteo' && campaign.type !== 'cpcfeed' && campaign.type !== 'adsense'">
          <b-button
            v-if="$route.name !== 'dashboard-campaigns-creatives-table'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ name: 'dashboard-campaigns-creatives-table' }"
            exact
          >
            <feather-icon
              icon="ImageIcon"
              size="16"
            />
            {{ $t('page.features.creativity') }}
          </b-button>
        </template>
        <template v-if="!authUserData.role.includes('admin') && selectedBanners === 0">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/stats`}"
          >
            <feather-icon
              icon="BarChart2Icon"
              size="16"
            />
            {{ $t('Statistics') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/impressions`}"
          >
            <feather-icon
              icon="UsersIcon"
              size="16"
            />
            {{ $t('page.features.impression') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            :to="`/users/${$route.params.userId}/campaigns/${$route.params.id}/clicks`"
            variant="flat-primary"
            class="btn-icon"
          >
            <feather-icon
              icon="MousePointerIcon"
              size="16"
            />
            {{ $t('Clicks sources') }}
          </b-button>
        </template>
        <template v-if="authUserData.role.includes('admin') && selectedBanners === 0">
          <b-button
            v-if="campaign.type == 'criteo'"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/criteo`}"
          >
            <b-img
              src="https://www.criteo.com/wp-content/themes/criteo2017/img/criteo-logo-orange.svg"
              blank-color="#ccc"
              width="64"
              alt="criteo"
            />
            Criteo
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/stats`}"
          >
            <feather-icon
              icon="BarChart2Icon"
              size="16"
            />
            {{ $t('Statistics') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/stats-sites`}"
          >
            <feather-icon
              icon="PieChartIcon"
              size="16"
            />
            {{ $t('Stats per sites') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/impressions`}"
          >
            <feather-icon
              icon="UsersIcon"
              size="16"
            />
            {{ $t('page.features.impression') }}
          </b-button> -->
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            :to="`/users/${$route.params.userId}/campaigns/${$route.params.id}/clicks`"
            variant="flat-primary"
            class="btn-icon"
          >
            <feather-icon
              icon="MousePointerIcon"
              size="16"
            />
            {{ $t('Clicks sources') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            :to="`/users/${$route.params.userId}/campaigns/${$route.params.id}/pixelclicks`"
            variant="flat-primary"
            class="btn-icon"
          >
            <feather-icon
              icon="ActivityIcon"
              size="16"
            />
            {{ $t('page.features.pixelclick') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            :to="`/users/${$route.params.userId}/campaigns/${$route.params.id}/geoclicks`"
            variant="flat-primary"
            class="btn-icon"
          >
            <feather-icon
              icon="GlobeIcon"
              size="16"
            />
            {{ $t('page.features.geoclick') }}
          </b-button>
        </template>
        <b-button
          v-if="selectedBanners === 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ path: `/users/${$route.params.userId}/campaigns/${$route.params.id}/edit`}"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
          />
          {{ $t('Settings') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  BImg,
  BButton,
  BFormFile,
  BButtonToolbar,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BButton,
    BFormFile,
    BButtonToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    selectedBanners: {
      type: Number,
      default: () => (0),
    },
    closing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authUserData: getUserData(),
    }
  },
}
</script>
